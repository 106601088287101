import React from 'react';
import { graphql, Link } from 'gatsby';
import { Table, Container, Row, Col } from 'reactstrap';

import { formatDate, isMoreDayEvent } from '../lib/date';
import Layout from '../components/Layout';

export const EventsPageTemplate = ({ events }) => (
  <Layout>
    <Container>
      <Row>
        <Col md={12}>
          <Table>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Název</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event, i) => (
                <tr key={i}>
                  <td>{event.dateText}</td>
                  <td><Link to={event.slug}>{event.title}</Link></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ({ data }) => {
  const events = data.allMarkdownRemark.edges.map(e => ({ ...e.node.frontmatter, slug: e.node.fields.slug }));
  return <EventsPageTemplate events={events} />
}

export const eventsQuery = graphql`
  query EventsTable {
    allMarkdownRemark(
      filter:{frontmatter: {templateKey: {eq: "event"}}}
      sort: {order: DESC, fields: frontmatter___eventDate}
    ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              dateText
              eventDate
            }
          }
        }
      }
  }
`;